import Config from 'config';
import callHandler from 'actions/api/common/axiosCallHandler';

export default async function triggerNotification(emails, notificationName, organisationId = null, attributes = null) {
    if (typeof emails === 'object' && !Array.isArray(emails) && emails !== null) {
        const payload = emails;
        const call = {
            method: "post",
            url: `${Config.get('ADMIN_URL')}trigger-notification`,
            data: payload
        };

        try {
            const response = await callHandler(call);
            return response;
        } catch (error) {
            console.error('Error triggering notification:', error);
            throw error;
        }
    } else {
        const data = {
            emails,
            notification: notificationName
        };

        if (organisationId) {
            data.organisationId = organisationId;
        }

        if (attributes) {
            data.attributes = attributes;
        }

        const call = {
            method: "post",
            url: `${Config.get('ADMIN_URL')}trigger-notification`,
            data
        };

        try {
            const response = await callHandler(call);
            return response;
        } catch (error) {
            console.error('Error triggering notification:', error);
            throw error;
        }
    }
}