import React from 'react';
import {Modal, Form, Table} from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import triggerNotification from 'actions/api/notifications/triggerNotification';
import {displayNotification} from 'actions/notifications';
import './notifications.scss';
import {connect} from 'react-redux';
import {DEFAULT_NOTIFICATION_TIMEOUT} from "../../../constants/notifications";
import {Multiselect} from 'multiselect-react-dropdown';

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            searchTerm: '',
            isProcessing: false,

            showLaunchingModal: false,
            launchingSelectedEmployees: [],
            launchingSelectAllChecked: false,

            showRegisteredModal: false,
            registeredSelectedEmployees: [],
            registeredSelectAllChecked: false,
            licensors: [],
            selectedLicensor: null
        };
    }
    loadEmployees = async () => {
        const {selectedOrg} = this.props;
        const {organisation_id} = selectedOrg || {};

        try {
            const employees = await listEmployees(organisation_id);
            this.setState({employees});
        } catch (err) {
            console.error('Failed to load employees');
        }
    }

    handleSearch = (event) => {
        this.setState({searchTerm: event.target.value});
    }

    getFilteredEmployees = () => {
        const {employees, searchTerm} = this.state;

        if (!searchTerm) {
            return employees;
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        return employees.filter(employee => {
            const {first_name, last_name, email, employee_id} = employee;
            return (
                first_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                last_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                email.toLowerCase().includes(lowerCaseSearchTerm) ||
                employee_id.toLowerCase().includes(lowerCaseSearchTerm)
            );
        });
    }

    openLaunchingModal = () => {
        this.setState({showLaunchingModal: true});
        this.loadEmployees();
    }

    closeLaunchingModal = () => {
        this.setState({
            showLaunchingModal: false,
            launchingSelectedEmployees: [],
            launchingSelectAllChecked: false,
            searchTerm: ''
        });
    }

    handleLaunchingEmployeeSelection = (employeeId) => {
        const {launchingSelectedEmployees} = this.state;

        if (launchingSelectedEmployees.includes(employeeId)) {
            this.setState({
                launchingSelectedEmployees: launchingSelectedEmployees.filter(id => id !== employeeId),
                launchingSelectAllChecked: false
            });
        } else {
            this.setState({
                launchingSelectedEmployees: [...launchingSelectedEmployees, employeeId]
            });
        }
    }

    handleLaunchingSelectAll = () => {
        const {employees, launchingSelectAllChecked} = this.state;
        if (!launchingSelectAllChecked) {
            const allEmployeeIds = employees.map(employee => employee.employee_id);
            this.setState({
                launchingSelectedEmployees: allEmployeeIds,
                launchingSelectAllChecked: true
            });
        } else {
            this.setState({
                launchingSelectedEmployees: [],
                launchingSelectAllChecked: false
            });
        }
    }

    handleSendLaunchingNotification = async () => {
        const {launchingSelectedEmployees, employees} = this.state;
        const {selectedOrg, displayNotification} = this.props;
        const organisationId = selectedOrg.organisation_id;
        const orgType = selectedOrg.type;

        const notificationType = orgType ? orgType.split(':')[0] : '';
        const notificationName = `${notificationType}_new_products`;

        const selectedEmployeeEmails = employees
            .filter(employee => launchingSelectedEmployees.includes(employee.employee_id))
            .map(employee => employee.email);

        this.setState({isProcessing: true});

        try {
            await triggerNotification(selectedEmployeeEmails, notificationName, organisationId);
            this.setState({isProcessing: false});
            displayNotification({
                type: 'success',
                message: 'Notification Sent',
                description: 'The notification was sent successfully to the selected employees.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            this.closeLaunchingModal();
        } catch (error) {
            console.error('Failed to send notification', error);
            this.setState({isProcessing: false});
            displayNotification({
                type: 'error',
                message: 'Notification Failed',
                description: 'There was an issue sending the notification. Please try again.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
        }
    }

    openRegisteredModal = () => {
        this.setState({showRegisteredModal: true});
        this.loadEmployees();
    }

    closeRegisteredModal = () => {
        this.setState({
            showRegisteredModal: false,
            registeredSelectedEmployees: [],
            registeredSelectAllChecked: false,
            selectedLicensors: [],
            searchTerm: ''
        });
    }

    handleRegisteredEmployeeSelection = (employeeId) => {
        const {registeredSelectedEmployees} = this.state;

        if (registeredSelectedEmployees.includes(employeeId)) {
            this.setState({
                registeredSelectedEmployees: registeredSelectedEmployees.filter(id => id !== employeeId),
                registeredSelectAllChecked: false
            });
        } else {
            this.setState({
                registeredSelectedEmployees: [...registeredSelectedEmployees, employeeId]
            });
        }
    }

    handleRegisteredSelectAll = () => {
        const {employees, registeredSelectAllChecked} = this.state;
        if (!registeredSelectAllChecked) {
            const allEmployeeIds = employees.map(employee => employee.employee_id);
            this.setState({
                registeredSelectedEmployees: allEmployeeIds,
                registeredSelectAllChecked: true
            });
        } else {
            this.setState({
                registeredSelectedEmployees: [],
                registeredSelectAllChecked: false
            });
        }
    }

    selectLicensor = (selectedLicensors) => {
        this.setState({ selectedLicensors });
    }

    removeLicensor = (selectedLicensors) => {
        this.setState({ selectedLicensors });
    }

    handleSendRegisteredNotification = async () => {
        const {registeredSelectedEmployees, employees, selectedLicensors} = this.state;
        const {selectedOrg, displayNotification} = this.props;
        const organisationId = selectedOrg.organisation_id;

        if (!selectedLicensors || selectedLicensors.length === 0) {
            displayNotification({
                type: 'error',
                message: 'Validation Error',
                description: 'Please select a licensor.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            return;
        }

        if (registeredSelectedEmployees.length === 0) {
            displayNotification({
                type: 'error',
                message: 'Validation Error',
                description: 'Please select at least one employee.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            return;
        }

        const selectedEmployeeEmails = employees
            .filter(employee => registeredSelectedEmployees.includes(employee.employee_id))
            .map(employee => employee.email);

        this.setState({isProcessing: true});

        try {
            const licensorObj = selectedLicensors[0];

            const payload = {
                emails: selectedEmployeeEmails,
                notification: "licensee_registered_products",
                organisationId: organisationId,
                attributes: {
                    licensor_name: licensorObj.name
                }
            };

            await triggerNotification(payload);

            this.setState({isProcessing: false});
            displayNotification({
                type: 'success',
                message: 'Notification Sent',
                description: 'The notification was sent successfully to the selected employees.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            this.closeRegisteredModal();
        } catch (error) {
            console.error('Failed to send notification', error);
            this.setState({isProcessing: false});
            displayNotification({
                type: 'error',
                message: 'Notification Failed',
                description: 'There was an issue sending the notification. Please try again.',
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
        }
    }

    renderEmployeeTable(selectedEmployees, handleSelection) {
        const {isProcessing} = this.state;
        const filteredEmployees = this.getFilteredEmployees();

        return (
            <>
                <Form.Group controlId="search" className="search-container">
                    <Form.Control
                        type="text"
                        placeholder="Search employees by name, email, or ID"
                        value={this.state.searchTerm}
                        onChange={this.handleSearch}
                        disabled={isProcessing}
                    />
                    {this.state.searchTerm && (
                        <span className="clear-search" onClick={() => this.setState({searchTerm: ''})}>X</span>
                    )}
                </Form.Group>

                <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                        <th width="5%"></th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Employee ID</th>
                        <th>Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEmployees.length > 0 ? filteredEmployees.map(employee => (
                        <tr
                            key={employee.employee_id}
                            onClick={() => handleSelection(employee.employee_id)}
                            className={`employee-row ${selectedEmployees.includes(employee.employee_id) ? 'selected' : ''}`}
                            style={{cursor: 'pointer'}}
                        >
                            <td>
                                <Form.Check
                                    type="checkbox"
                                    checked={selectedEmployees.includes(employee.employee_id)}
                                    onChange={() => handleSelection(employee.employee_id)}
                                    disabled={isProcessing}
                                />
                            </td>
                            <td>{employee.first_name}</td>
                            <td>{employee.last_name}</td>
                            <td>{employee.employee_id}</td>
                            <td>
                                <span title={employee.email}>{employee.email}</span>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td colSpan="5" className="text-center">
                                There is no data to show right now.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </>
        );
    }

    render() {
        const {
            showLaunchingModal,
            showRegisteredModal,
            launchingSelectedEmployees,
            registeredSelectedEmployees,
            selectedLicensors,
            isProcessing
        } = this.state;

        const { organisations = [] } = this.props;

        return (
            <div>
                <h1>Notifications</h1>


                <div className="row marg-t">
                    <div className="col-md-6">
                        <h4>New products launching at retail this month</h4>
                    </div>
                    <div className="col-md-6">
                        <button className="submit btn btn-primary" onClick={this.openLaunchingModal}>
                            Select Employees
                        </button>
                    </div>
                </div>


                <div className="row marg-t">
                    <div className="col-md-6">
                        <h4>New Products Registered in Xelacore</h4>
                    </div>
                    <div className="col-md-6">
                        <button className="submit btn btn-primary" onClick={this.openRegisteredModal}>
                            Select Licensor and Employees
                        </button>
                    </div>
                </div>

                <Modal
                    show={showLaunchingModal}
                    onHide={this.closeLaunchingModal}
                    size="lg"
                    dialogClassName="modal-employee-list"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Employees</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.renderEmployeeTable(launchingSelectedEmployees, this.handleLaunchingEmployeeSelection)}
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-light" onClick={this.closeLaunchingModal} disabled={isProcessing}>
                            Close
                        </button>
                        <button
                            className="submit btn btn-primary"
                            onClick={this.handleSendLaunchingNotification}
                            disabled={isProcessing || launchingSelectedEmployees.length === 0}
                        >
                            {isProcessing ? 'Sending...' : 'Send Notification'}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showRegisteredModal}
                    onHide={this.closeRegisteredModal}
                    size="lg"
                    dialogClassName="modal-employee-list"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Licensor and Employees</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group controlId="licensorSelect" className="mb-4">
                            <Form.Label><strong>Select Licensor</strong> <span className="text-danger">*</span></Form.Label>
                            <Multiselect
                                menuShouldScrollIntoView={false}
                                options={organisations.filter(org => org.type === 'licensor')}
                                selectedValues={selectedLicensors}
                                onSelect={this.selectLicensor}
                                onRemove={this.removeLicensor}
                                displayValue="name"
                                singleSelect={true}
                                disable={isProcessing}
                                className="mb-3"
                                selectionLimit={1}
                                avoidHighlightFirstOption={true}
                                placeholder="Select a licensor..."
                            />
                        </Form.Group>

                        <Form.Label><strong>Select Employees</strong> <span className="text-danger">*</span></Form.Label>
                        {this.renderEmployeeTable(registeredSelectedEmployees, this.handleRegisteredEmployeeSelection)}
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-light" onClick={this.closeRegisteredModal} disabled={isProcessing}>
                            Close
                        </button>
                        <button
                            className="submit btn btn-primary"
                            onClick={this.handleSendRegisteredNotification}
                            disabled={
                                isProcessing ||
                                registeredSelectedEmployees.length === 0 ||
                                !selectedLicensors ||
                                selectedLicensors.length === 0
                            }
                        >
                            {isProcessing ? 'Sending...' : 'Send Notification'}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function globalStateMapper(globalState) {
    const {organisations = {}} = globalState;
    return {
        organisations: organisations.list || [],
    };
}

export default connect(
    globalStateMapper,
    {displayNotification}
)(Notifications);