import React, {Component} from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import {omit} from 'lodash';
import OrgContact from './OrgContact';
import AddContactModal from './AddContactModal';

class OrgContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableContactTypes: [],
            usedContactTypes: [],
            contacts: {},
            showAddContactPopup: false,
            addContactData: {},
        };
        this.allowedContactTypes = [
            'Finance_contact', 'Marketing_contact', 'Primary_ambassador', 'Relationship_executive'
        ];
    };

    onHandleDelete = (contact) => {
        const {contacts = []} = this.props;
        const {contact_id} = contact;

        if (!contact_id) return;

        let existing = contacts.filter((ctc) => ctc.contact_id !== contact_id);
        let contactsObject = {};
        existing.forEach(ctc => contactsObject[ctc.type] = omit(ctc, ['contact_id']));
        this.props.handleOrgInfoUpdate({information: {contacts: contactsObject}}, false, true);
    }

    onHandleSave = (contact) => {
        this.props.handleOrgInfoSubmit();
    }

    onHandleUpdate = (contact) => {
        const {contacts = []} = this.props;
        const {contact_id} = contact;

        if (!contact_id) return;

        let existing = contacts.filter((ctc) => ctc.contact_id !== contact_id);
        existing.push(contact)
        let contactsObject = {};
        existing.forEach(ctc => contactsObject[ctc.type] = omit(ctc, ['contact_id']));
        this.props.handleOrgInfoUpdate({information: {contacts: contactsObject}})
    }

    toggleAddContactPopup = () => {
        const {showAddContactPopup} = this.state;
        this.setState({showAddContactPopup: !showAddContactPopup});
    }

    handleAdd = (contact) => {
        const {contacts = []} = this.props;
        contacts.push(contact);
        let contactsObject = {};
        contacts.forEach(ctc => contactsObject[ctc.type] = omit(ctc, ['contact_id']));
        this.props.handleOrgInfoUpdate({information: {contacts: contactsObject}}, false, true);
    }

    render() {
        const {contacts = []} = this.props;
        let existingContactTypes = contacts.map((ctc) => ctc.type.toLowerCase());
        let remainingContactTypes = this.allowedContactTypes.filter((type) => !existingContactTypes.includes(type.toLowerCase()))

        return (
            <div>
                <Row className="mt-2">
                    <Col>
                        <Button className="add"
                                onClick={this.toggleAddContactPopup}
                                disabled={remainingContactTypes.length === 0}
                        >
                            New Contact
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Form>
                        {
                            contacts.map(contact => {
                                return (
                                    <OrgContact
                                        key={contact.contact_id}
                                        contact={contact}
                                        id={contact.contact_id}
                                        onHandleUpdate={this.onHandleUpdate}
                                        onHandleDelete={() => this.onHandleDelete(contact)}
                                        onHandleSave={() => this.onHandleSave(contact)}
                                    />
                                )
                            })
                        }
                        {
                            contacts.length === 0
                                ?
                                <div className="mt-5"><h3>No Contacts listed</h3></div>
                                :
                                ""
                        }
                    </Form>
                </Row>
                <AddContactModal
                    show={this.state.showAddContactPopup}
                    togglePopUp={this.toggleAddContactPopup}
                    remainingContactTypes={remainingContactTypes}
                    onAddContact={this.handleAdd}
                />
            </div>
        )
    }
}

export default OrgContacts;