import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class UserSelector extends Component {
  constructor(props){
    super(props);
    this.state = {
      localUsers: []
    };
    this.onUserSelect = this.onUserSelect.bind(this);
  }

  componentDidMount() {
    this.updateLocalUsers(this.props.users);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.updateLocalUsers(this.props.users);
    }
  }

  updateLocalUsers(users) {
    const hasNoneSelected = users.some(u => u.email === "None Selected");
    const updatedUsers = hasNoneSelected ?
        [...users] :
        [{ email: "None Selected" }, ...users];
    this.setState({ localUsers: updatedUsers });
  }

  onUserSelect(e) {
    let selectedUser = JSON.parse(e.target.value);
    this.props.onUserSelect(selectedUser);
  }

  render() {
    return (
        <div className="userSelect">
          <Form.Group controlId="userSelect">
            <Form.Label>Select user</Form.Label>
            <Form.Control as="select" onChange={this.onUserSelect}>
              {this.state.localUsers.map((u) => {
                return <option key={u.email} value={JSON.stringify(u)}>{u.email}</option>
              })}
            </Form.Control>
          </Form.Group>
        </div>
    );
  }
}

export default UserSelector;