import React, {Component} from 'react';
import {connect} from "react-redux";
import {Multiselect} from 'multiselect-react-dropdown';
import {Form, Container, Button, Col} from 'react-bootstrap';
import {licenseesorTable} from './tableheader'
import CustomTableWithActions from 'components/CustomTableWithActions';
import createLink from 'actions/api/links/create';
import listLinks from 'actions/api/links/list';
import deleteLink from 'actions/api/links/delete';
import ErrorAlert from 'components/ErrorAlert';
import EmployeesModal from 'components/EmployeesModal';
import listEmployees from 'actions/api/employees/list';
import moment from 'moment/moment';

function globalStateMapper(globalState) {
    const {organisations = {}} = globalState;
    return {
        organisations: organisations.list,
    };
}

class LinkToLicenseesor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisations: [],
            selectedLinkOrgs: [],
            employeesModal: false,
            licenseeObj: {},
            employees: []
        }
        this.onSelectLinkOrg = this.onSelectLinkOrg.bind(this);
        this.handleLinkOrgs = this.handleLinkOrgs.bind(this);
        this.handleDeleteOrgLink = this.handleDeleteOrgLink.bind(this);
        this.fetchLinks = this.fetchLinks.bind(this);
        this.removeLinkOrg = this.removeLinkOrg.bind(this);
        this.selectLinkOrg = this.selectLinkOrg.bind(this);
    };

    componentDidMount() {
        this.fetchLinks();
    }

    fetchLinks() {
        let {selectedOrg} = this.props;
        let {organisation_id, type} = selectedOrg || {};
        return listLinks(organisation_id, type)
            .then((data) => {
                this.setState({links: data})
            });
    }

    handleLinkOrgs() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        let {selectedLinkOrgs} = this.state;

        Promise.all(selectedLinkOrgs.map(org => {
            let linkInfo = {
                orgFrom: selectedOrg,
                orgTo: org
            }
            return createLink(organisation_id, linkInfo).catch(err => {
                console.error(err);
            });
        })).then((data) => {
            this.setState({selectedLinkOrgs: []})
            this.fetchLinks();
        });
    }

    handleDeleteOrgLink(link) {
        const {link_id: linkId = null} = link;
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        return deleteLink(organisation_id, linkId).then((data) => {
            this.fetchLinks();
        });
    }

    onSelectLinkOrg(e) {
        let selectedLinkOrg = JSON.parse(e.target.value);
        this.setState({selectedLinkOrg});
    }

    selectLinkOrg(selectedLinkOrgs, selectedItem) {
        this.setState({selectedLinkOrgs});
    }

    removeLinkOrg(selectedLinkOrgs, removedItem) {
        this.setState({selectedLinkOrgs});
    }

    fetchEmployees(licensee) {
        listEmployees(licensee.licensee_organisation_id)
            .then((data) => {
                this.setState({employees: data, licenseeObj: licensee})
                this.toggleEmployeesModal();
            })
            .catch(err => alert(err.message));
    }

    toggleEmployeesModal() {
        this.setState({employeesModal: !this.state.employeesModal})
    }

    render() {
        const {selectedLinkOrgs, links = [], employeesModal, licenseeObj, employees} = this.state;
        const {selectedOrg, organisations, errors, revalidate} = this.props;
        const {type} = selectedOrg;
        const typeToLinkTo = type === 'licensee' ? 'licensor' : 'licensee';

        return (
            <div className={`LinkToLicenseesor data-capture-section ${type === 'licensor' ? 'licensor' : 'licensee'}`}>
                <ErrorAlert errors={errors} revalidate={revalidate}></ErrorAlert>

                <Container fluid>
                    <h2>Links ({links.length})</h2>
                    <Form>
                        <Form.Row>
                            <Col lg>
                                <Form.Group controlId="orgLinkSelect">
                                    <Form.Label>Select {type === 'licensee' ? 'Licensor' : 'Licensee'} Orgs To Link To -
                                        You currently
                                        have {links.length} linked {type === 'licensee' ? 'Licensors' : 'Licensees'}</Form.Label>
                                    <Multiselect
                                        menuShouldScrollIntoView={false}
                                        options={organisations.filter(f => f.type === typeToLinkTo && !(links || []).map(x => x[`${typeToLinkTo}_organisation_id`]).includes(f.organisation_id))} // Options to display in the dropdown
                                        selectedValues={selectedLinkOrgs} // Preselected value to persist in dropdown
                                        onSelect={this.selectLinkOrg} // Function will trigger on select event
                                        onRemove={this.removeLinkOrg} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs>
                                <Button className="add mt-4" onClick={this.handleLinkOrgs}>
                                    Add
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                    {
                        links && links.length > 0
                            ? <CustomTableWithActions
                                selectedOrg={selectedOrg}
                                data={this.state.links.map(link => ({
                                    ...link,
                                    created_at: moment(link.created_at).format("YYYY-MM-DD HH:mm:ss [GMT]Z"),
                                    updated_at: moment(link.updated_at).format("YYYY-MM-DD HH:mm:ss [GMT]Z")
                                }))}
                                tableheader={licenseesorTable.filter(item => item.hideFor !== selectedOrg.type)}
                                links={{
                                    [type === 'licensee' ? 'licensor_organisation_id' : 'licensee_organisation_id']: {
                                        name: type === 'licensee' ? 'licensor_organisation_id' : 'licensee_organisation_id',
                                        type: 'organisations',
                                        className: 'link'
                                    }
                                }}
                                actions={[
                                    {
                                        name: "Remove",
                                        onClick: this.handleDeleteOrgLink,
                                        message: "You are about to UNLINK these 2 organisations, this will prevent data being shared between them",
                                        confirmation: true,
                                        className: 'delete'
                                    },
                                    selectedOrg.type === 'licensor' ? {
                                        name: "Notify Licensee",
                                        className: 'notify',
                                        onClick: (l) => this.fetchEmployees(l),
                                        confirmation: false
                                    } : {name: undefined}
                                ]}
                            />
                            : <p>No Links to show</p>
                    }
                </Container>

                <EmployeesModal show={employeesModal} togglePopUp={() => this.toggleEmployeesModal()}
                                licenseeObj={licenseeObj} employees={employees}></EmployeesModal>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(LinkToLicenseesor);
