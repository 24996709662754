import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Container, Table, Dropdown, DropdownButton} from 'react-bootstrap';
import moment from 'moment';
import memoize from 'memoize-one';
import Pagination from 'components/lists/Pagination';
import {getPageItems} from "utils/pagination";

import {getLogins} from 'actions/api/users/users';
import triggerNotification from 'actions/api/notifications/triggerNotification';
import {displayNotification} from "../../../actions/notifications";
import {DEFAULT_NOTIFICATION_TIMEOUT} from "../../../constants/notifications";

function globalStateMapper(globalState) {
    return {};
}

class LastLogins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastLogins: [],
            currentPage: 1,
            itemsPerPage: 10,
            sendingReminder: false,
        }
    }

    componentDidMount() {
        this.getLastLogins();
    }

    getLastLogins = () => {
        let {selectedUser} = this.props;

        getLogins(selectedUser.user_id).then((response) => {
            this.setState({
                lastLogins: response.data && response.data.logins && response.data.logins.data.loginAttempts
            })
        }, () => {
        })
    };

    onPageChanged = (currentPage) => {
        this.setState({
            currentPage
        });
    };

    sendLoginReminderAction = (notificationName) => {
        const { selectedUser } = this.props;
        const email = selectedUser.email;

        this.setState({ sendingReminder: true });

        triggerNotification([email], notificationName)
            .then(() => {
                this.setState({ sendingReminder: false });
                this.props.displayNotification({
                    type: 'success',
                    message: 'Reminder Sent',
                    description: `Login reminder using ${notificationName} sent successfully.`,
                    timeout: DEFAULT_NOTIFICATION_TIMEOUT
                });
            })
            .catch((error) => {
                console.error('Error sending login reminder:', error);
                this.setState({ sendingReminder: false });
                this.props.displayNotification({
                    type: 'error',
                    message: 'Failed to Send Reminder',
                    description: 'There was an error sending the login reminder.',
                    timeout: DEFAULT_NOTIFICATION_TIMEOUT
                });
            });
    };

    paginateList = memoize(getPageItems);

    render() {
        const {lastLogins, itemsPerPage, currentPage, sendingReminder} = this.state;
        const totalItems = Math.ceil(lastLogins.length / itemsPerPage);
        const paginatedList = this.paginateList(lastLogins, currentPage, itemsPerPage);

        return (
            <div className="last-logins data-capture-section">
                <Container fluid className="pt-2">
                    <div className={'row'}>
                        <div className={'col-md-8'}>
                            <h2 className="mb-3">Last Logins</h2>
                        </div>
                        <div className={'col-md-4'}>
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={sendingReminder ? 'Sending...' : 'Send Login Reminder'}
                                variant="primary"
                                disabled={sendingReminder}
                            >
                                <Dropdown.Item
                                    onClick={() => this.sendLoginReminderAction('licensee_login_reminder')}>
                                    Use Licensee template
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.sendLoginReminderAction('licensor_login_reminder')}>
                                    Use Licensor template
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.sendLoginReminderAction('agent_login_reminder')}>
                                    Use Agent template
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>

                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Success</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            paginatedList.map(item => (
                                <tr key={item.timestamp}>
                                    <td>
                                        {moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss [GMT]Z")}
                                    </td>
                                    <td className={`status ${item.success ? 'completed' : 'failed'}`}>
                                        {item.success ? 'True' : 'False'}
                                    </td>
                                </tr>
                            ))
                        }

                        {!lastLogins.length && (
                            <tr>
                                <td className="no-logins" colSpan="2">
                                    <span>No login attempts found.</span>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        onPageChanged={this.onPageChanged}
                    />
                </Container>
            </div>
        )
    }
}

export default connect(null,
    {globalStateMapper,displayNotification}
)(LastLogins);
