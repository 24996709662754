export const waiversTable = [
    {
        field: 'created_at',
        label: 'Date',
        order: 0,
    },
    {
        field: 'gtin_waiver',
        label: 'Action',
        order: 1,
    },
    {
        field: 'actioning_username',
        label: 'Name',
        order: 2,
    },
    {
        field: 'reason',
        label: 'Reason',
        order: 3,
    },
    {
        field: 'requesting_username',
        label: 'Requester',
        order: 4,
    }
  ]