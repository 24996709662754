import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import BasicTable from "../../BasicTable";
import listEmployeesPending from 'actions/api/employees/list-pending';
import Config from 'config'
import moment from "moment/moment";
import CustomTableWithActions from "../../CustomTableWithActions";
import { employeesPendingTable } from "./tableheader";

import resendInvite from 'actions/api/employees/resend-invite';
import deleteInvite from 'actions/api/employees/delete-invite';
import jwtDecode from "jwt-decode";
import { getAuthStorage } from "../../../utils/storage";
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS } from "../../../constants/notifications";
import { displayNotification } from "../../../actions/notifications";
import { connect } from "react-redux";

class PendingUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            selectedUser: null,
            showAddUserPopup: false,
            showInviteUserPopup: false,
            employeesPending: []
        }
    };

    componentDidMount() {
        this.fetchEmployeesPending();
    }

    fetchEmployeesPending = () => {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};

        listEmployeesPending(organisation_id)
            .then((data) => {
                this.setState({ employeesPending: data })
            })
            .catch(err => alert(err.message));
    }

    handleResendInvite = (employee) => {
        const authStorage = getAuthStorage();
        const userInfo = !!authStorage.org_access_token && jwtDecode(authStorage.org_access_token)
            ? jwtDecode(authStorage.org_access_token).data
            : {};
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};
        resendInvite(organisation_id, employee.invite_token, userInfo.user_id).then(() => {
            this.props.displayNotification({
                message: 'Success, Invite was Sent.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            this.fetchEmployeesPending();
        });
    }

    handleDeleteInvite = (employee) => {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};
        deleteInvite(organisation_id, employee.invite_token).then(() => {
            this.props.displayNotification({
                message: 'Success, Invite was deleted.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT
            });
            this.fetchEmployeesPending();
        })
    }

    formatDateWithTimezone = (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss [GMT]Z");
    }

    render() {
        const { employeesPending } = this.state;

        return (
            <div className="Employees pending-users data-capture-section p-0 mt-5">
                <Container fluid className="pt-2">
                    <h2>Pending Users</h2>
                    <CustomTableWithActions
                        data={employeesPending.filter(x => !!x).map(
                            item => {
                                return {
                                    'Email': item.email,
                                    'Date Created': this.formatDateWithTimezone(item.created_at),
                                    'Link': `${Config.get('services').login}/register?inviteToken=${item.invite_token}`,
                                    'invite_token': `${item.invite_token}`,
                                }
                            })
                        }
                        tableheader={employeesPendingTable}
                        actions={[
                            {
                                name: "Delete",
                                className: 'delete',
                                onClick: this.handleDeleteInvite,
                                message: "You are about to Delete this invite",
                                confirmation: true
                            },
                            {
                                name: "Resend",
                                className: 'notify',
                                onClick: this.handleResendInvite,
                                message: "You are about to Resend this invite",
                                confirmation: true
                            },
                        ]}
                    />
                </Container>
            </div>
        );
    }
}

export default connect(
    null,
    { displayNotification }
)(PendingUsers);